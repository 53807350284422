
:root {
  --black: #000000;
  --white: #ffffff;
  --secondary: #818181;
  --primary: #ffffff;
  --darkblack: #1e1e25;
  --tabbackgroundcolor: rgba(255, 255, 255, 0.1);
  --darkGreyColor: rgba(255, 255, 255, 0.5);
  --white80: #ffffff80;
  --white50: #ffffff50;
  --grey50Rga: rgba(255, 255, 255, 0.5);
  --borderColor: rgba(255, 255, 255, 0.1);
  --mainBackgroundColor: white;
}

.custom-dropdown {
  position: relative;
  cursor: pointer;
  flex: 1;
  display: inline-block;
  padding: 0.5rem;
 
}

.custom-dropdown2 {
  font-size: 17px;
  font-weight: 600;
  flex: 1;
  color: var(--white);
  position: relative;
}
.downArrowIcon {
  position: "relative";
  width: 1.5rem;
  object-fit: contain;
  height: 1.5rem;
}

.dropdown-menu {
  border: 1px solid var(--borderColor);
  list-style: none;
  padding: 0;
  position: absolute;
  margin: 0;
  background-color:black;
  width: 100%;
  border-radius: 5px;
  border: 2px solid rgba(6, 101, 228, 0.3);
 
}

.dropdown-menu li {
  padding: 10px;
  font-size: 18;
  font-weight: 450;
  color: black;
  cursor: pointer;
  z-index: 2;
 
}

.dropdown-menu li:hover {
  color: rgba(10, 132, 255, 1);
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu .active{
  color: rgba(10, 132, 255, 1);
}