@tailwind base;
@tailwind components;
@tailwind utilities;

.font-48 {
  font-size: 35px;
  font-weight: 100;
  line-height: 35px;
}

.font-24 {
  font-size: 18px;
  line-height: 24px;
}

.font-30 {
  font-size: 25px;
  line-height: 28px;
}

.font-16 {
  font-size: 13px;
}

.font-40 {
  font-size: 30px;
  font-weight: 100;
}

.font-32 {
  font-size: 26px;
  font-weight: 500;
}

.font-20 {
  font-size: 16px;
}

.font-75 {
  font-size: 50px;
  line-height: 55px;
}

.videoplay_rs {
  display: none;
}

.showbr {
  display: none;
}

.calaneder .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: fit-content !important;
  padding: 5px 14px;
  font-family: HelveticaLight !important;
}

.calaneder .react-datepicker__header {
  background-color: antiquewhite !important;
  border: none !important;
}

.calaneder h2.react-datepicker__current-month {
  font-family: HelveticaLight !important;
}

.calaneder .react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: antiquewhite !important;
  color: black !important;
}

.calaneder .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: antiquewhite !important;
  color: black !important;
}

@media (max-width: 1376px) {
  .publishing {
    padding: 0px 100px !important;
  }
}

@media (max-width: 1199px) {
  .nav_bar {
    gap: 40px !important;
  }

  .nav_min {
    margin-left: 0px !important;
  }

  .font-24 {
    font-size: 14px;
    font-weight: 100;
    line-height: 25px;
  }

  .new_navbar {
    gap: 10px !important;
  }

  .font-48 {
    font-size: 23px;
    font-weight: 100;
  }

  .font-75 {
    font-size: 40px;
    line-height: 40px;
  }

  .font-30 {
    font-size: 20px;
    font-weight: 100;
  }

  .font-40 {
    font-size: 20px;
    font-weight: 100;
  }

  .font-32 {
    font-size: 20px;
  }
}

@media (max-width: 1199px) {
  .min_search {
    justify-content: center;
    border-radius: 50px;
    gap: 41px;
  }

  .min_top_search {
    border-radius: 50px;
  }

  .min_top_search .w-\[1px\] {
    display: none;
  }

  .min_top_search .ml-5 {
    margin-left: 0px;
  }
}

@media (max-width: 682px) {
  .min_search {
    justify-content: left;
    border-radius: 50px;
    gap: 41px;
    padding-left: 30px;
  }

  .min_search input::placeholder {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .min_search .white_class {
    padding: 15px;
  }
}

@media (max-width: 500px) {
  .min_search .orange_class {
    padding: 15px;
  }
}

@media (max-width: 1020px) {
  .koze_home_slider .slick-dots {
    bottom: 140px !important;
  }
}

@media (max-width: 991px) {
  .nav_min {
    position: fixed;
    background-color: #000000;
    top: 100px;
    z-index: 9999;
    gap: 20px !important;
    padding: 20px !important;
    padding-right: 70px !important;
  }

  .publishing {
    padding: 0px 20px !important;
  }

  .Contact_Information {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .Information_w {
    width: 100% !important;
  }

  .Information_t {
    width: 100% !important;
  }

  .navbar-toggler {
    margin-left: 50px;
  }

  .new_navbar {
    display: flex !important;
    flex-direction: row !important;
  }

  .header_min .nav-item .active {
    color: white !important;
    background-color: transparent !important;
    padding: 0px !important;
  }

  .header_min .nav_min .nav-item a {
    color: white !important;
  }

  .header_min .nav_min {
    align-items: flex-start !important;
  }

  .header_min .haeder_logo {
    width: 140px;
  }

  .header_min {
    padding: 20px 0px !important;
  }

  .new_navbar {
    gap: 30px !important;
  }

  .menu_rs {
    display: none !important;
  }

  .menu_rl {
    display: flex !important;
  }

  .Discover_flex {
    display: block !important;
  }

  .Discover_flex .experience_P {
    padding: 10px 0px !important;
  }

  .Housekeeping {
    grid-template-columns: repeat(1, 1fr) !important;
    max-width: 450px;
    margin: auto;
  }

  .Reserved_flex {
    flex-wrap: wrap;
  }

  .w_footer {
    width: 100% !important;
    margin-bottom: 70px;
  }

  .SushiBar {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px !important;
  }

  .SushiBar p {
    margin-bottom: 0px !important;
  }

  .Canadian_flex {
    display: flex;
    gap: 50px;
  }

  .Tennis_as {
    width: 45%;
  }

  .Scandinavian {
    width: 47%;
  }

  .Canadian_flex .Tremblant-mt {
    margin-top: 0px !important;
  }

  .publishing .Laurentians_slider {
    padding: 0px 45px !important;
  }

  .Testimonial_min .next-button {
    margin-left: -10px;
  }

  .Testimonial_min .prev-button {
    right: 8px !important;
  }

  .dubai_slider .slide {
    height: 500px !important;
  }
}

@media (max-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px !important;
    padding: 0px 20px !important;
  }

  .custom-dropdown {
    padding: 3px !important;
  }
}

@media (max-width: 700px) {
  .publishing .slide {
    padding-left: 20px !important;
    padding-right: 20 !important;
  }

  .w_footer {
    text-align: center;
  }

  .Newsletter {
    margin-top: 20px;
  }

  .locationIcon {
    left: -76px !important;
    gap: 10px !important;
  }

  .dubai_slider .slide .font-30 {
    font-size: 15px !important;
  }

  .locationIcon img {
    width: 20px !important;
    height: 12px !important;
  }

  .font-75 {
    font-size: 35px;
    line-height: 40px;
  }

  .publishing .slide {
    padding-right: 18px !important;
  }

  .publishing .Laurentians_slider {
    padding: 0px 22px !important;
  }

  .marker_slider .publishing .Laurentians_slider::after {
    display: none;
  }

  .publishing .slick-prev {
    left: -29px !important;
  }
}

@media (max-width: 600px) {
  .koze_home_slider .slick-dots {
    bottom: 80px !important;
  }
}

@media (max-width: 580px) {
  .navbar-toggler {
    margin-left: 0px;
  }

  .container-fluid {
    display: block !important;
    text-align: center;
  }

  .new_navbar {
    margin: auto;
    margin-top: 20px !important;
    gap: 0px !important;
    justify-content: space-between;
    min-width: 100% !important;
  }

  .header_min {
    padding: 7px 0px !important;
  }

  .new_navbar {
    margin-top: 9px !important;
  }

  .Information_t input {
    padding: 8px 35px !important;
  }

  .Information_t textarea {
    padding: 16px 35px !important;
  }

  .font-75 {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 550px) {
  .Canadian_flex {
    display: block;
    gap: 50px;
  }

  .Tennis_as {
    width: 100%;
  }

  .Scandinavian {
    width: 100%;
  }

  .Canadian_flex .Tremblant-mt {
    margin-top: 30px !important;
  }

  .Testimonial_min .slide {
    padding-right: 0px !important;
  }

  .Testimonial_min .Clients .slick-initialized::before {
    display: none;
  }

  .Testimonial_min .Clients .slick-initialized::after {
    display: none;
  }

  .Newsletter input {
    font-size: 14px !important;
  }

  .Newsletter input::placeholder {
    font-size: 14px !important;
  }

  .signup .btn-14 {
    font-size: 12px !important;
  }

  .w_footer img {
    width: 200px !important;
  }

  .dubai_slider .slide {
    height: 350px !important;
  }
}

@media (max-width: 500px) {
  .Question_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 30px;
    margin-top: 30px;
  }
}

@media (max-width: 422px) {
  .koze_ml {
    margin-left: auto !important;
  }
}

.container {
  margin: auto;
  padding: 0px 20px;
}

@media (max-width: 380px) {
  .home_away .slick-dots {
    gap: 4px !important;
  }
}

@media (max-width: 1023px) {
  .collapse_txte {
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .collapse_txte {
    display: -webkit-inline-box;
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: none;
    overflow: hidden;
  }

  .txt__character {
    letter-spacing: 30px !important;
  }

  .Landing_slider .txt {
    font-size: 100px !important;
  }

  .Landing_slider .page {
    width: 391px !important;
  }

  .Currency_count {
    text-align: center;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .About_us br {
    display: inline-block !important;
    margin: 0px 4px;
    display: contents !important;
    display: none !important;
  }

  .showbr {
    margin-left: 0px !important;
    display: inline-block;
  }

  .showbr::before {
    content: "." !important;
    color: transparent;
    visibility: hidden;
  }

  .dot_jh::after {
    display: none;
  }

  .dot_jh {
    border: none;
  }

  .dot_jh {
    padding: 0px;
  }

  .slashedzero .flex {
    gap: 0px;
  }

  .slashedzero img {
    margin-top: 20px;
  }

  .Own_Rentals {
    position: relative;
    display: block;
    height: fit-content !important;
    min-height: fit-content !important;
  }

  .Vidoebanner {
    position: relative;
    height: fit-content !important;
    min-height: fit-content !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .videoplay_d {
    display: none;
  }

  .videoplay_rs {
    display: block;
    z-index: 9999;
    position: absolute;
    padding: 0px;
    margin: 0px;
  }

  .videoplay_rs img {
    width: 50px;
  }

  .Own_Rentals video {
    position: relative;
    height: auto !important;
    min-height: auto !important;
  }

  .Own_Rentals .grid-cols-2 {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  .Vidoebanner_img {
    height: inherit !important;
    min-height: inherit !important;
    top: 0px;
    object-fit: cover;
  }
}

.Information_t .message_black_to_white {
  padding-top: 35px !important;
  padding-left: 30px;
}

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 42, 40, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.success-popup-content {
  background-color: black;
  color: green;
  font-size: 20px;
  padding: 20px;
  border-radius: 10px;
  display: grid;
  width: 40%;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.success-popup-content h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #bd8833 !important;
  margin-top: 0px;
}

.success-popup-content button {
  background-color: #bd8833;
  padding: 5px 15px;
  margin: auto;
  border-radius: 10px;
  font-size: 17px;
  color: white;
}

.success-popup-content button:hover {
  background-color: light-dark(#efedea, #223a2c);
  color: black;
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLefddt !important;
  position: relative;
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRighfft !important;
  position: relative;
}

.bounce {
  animation-name: bouncdec !important;
  position: relative;
}

.Housekeeping .animated {
  position: relative;
}

img {
  overflow-clip-margin: unset;
}

.sevices_icon {
  width: 56px;
  height: 56px;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid #887963;
  padding: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sevices_icon::before {
  content: "";
  height: 45px;
  width: 45px;
  background-color: #887963;
  display: block;
  border-radius: 100%;
  position: absolute;
}

.sevices_icon img {
  position: relative;
  height: 30px;
  width: 30px;
}

@media (max-width: 460px) {
  .koze_home_slider video {
    min-height: 340px;
    height: 20vh;
  }

  .Landing_slider .page {
    width: 315px !important;
  }

  .Landing_slider .txt {
    font-size: 70px !important;
  }

  .koze_home_slider .slick-dots {
    bottom: 45px !important;
  }

  .min_top_search {
    border-radius: 20px;
    margin-top: -26px;
  }

  .min_search {
    border-radius: 20px;
  }
}

@media (max-width: 374px) {
  .koze_home_slider video {
    min-height: 250px;
    height: 20vh;
  }
}

@media (max-width: 320px) {
  .Landing_slider .txt {
    font-size: 50px !important;
  }

  .Landing_slider .page {
    width: 250px !important;
  }
}

html {
  scroll-behavior: smooth;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.subject_data .dropdown-menu {
  border: 1px solid var(--borderColor);
  list-style: none;
  padding: 0;
  position: absolute;
  margin: 0;
  background-color: white;
  width: 100%;
  border-radius: 5px;
  border: 2px solid rgba(6, 101, 228, 0.3);
  font-family: Optima;
}

.subject_data .custom-dropdown2 {
  color: white;
}

.subject_data {
  border: 0.5px solid white;
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 14px;
  padding-left: 25px;
  padding-right: 25px;
}

.subject_data .custom-dropdown2 {
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  width: 100%;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 15px;
  font-weight: 100;
}
.subject_data .downArrowIcon {
  filter: brightness(15);
}

.countryCode {
  border: 0.5px solid white;
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
}
.countryCode .subject_data {
  border: none;
  padding-right: 0px;
  width: 70px;
}
.countryCode .downArrowIcon {
  display: none;
}
.Ask_Away .countryCode input {
  border: none !important;
  padding-left: 0px !important;
}
.countryCode .dropdown-menu {
  width: 80px;
}
.newBoardcode {
  border: 0.5px solid black;
  color: black;
}
.Information_t .newBoardcode input {
  border: none !important;
  padding-left: 0px !important;
}
.newBoardcode .custom-dropdown2 {
  color: #000000;
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 0 !important;
}
.video-react.video-react-fluid video {
  object-fit: cover !important;
}

.rpv-core__inner-page {
  background-color: #000 !important;
}

.marque_container {
  border-radius: 20px;
  background-color: #f9f9f9;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 70px;
  transition: border-radius 0.3s ease-out;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}

.carousel-image {
  z-index: 2;
  object-fit: contain;
  width: 100%;
  max-width: 90%;
  position: relative;
}

.marque_container:hover {
  cursor: pointer;
  background-color: #e0e0e0;
  
}


@media (max-width: 600px) {
  .marque_container {
    border-radius: 12px;
    width: 140px;
    height: 50px;
  }
}