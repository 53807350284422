@font-face {
  font-family: Helvetica;
  src: url(./Assets//font-famliy/Helvetica\ Light\ Condensed.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: HelveticaLight;
  src: url(./Assets//font-famliy/Helvetica.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: georgia;
  src: url(./Assets//font-famliy/georgia.ttf);
}


@font-face {
  font-family: Aileron;
  src: url(./Assets/font-famliy/aileron/Aileron-Regular.otf);
}

@font-face {
  font-family: Optima;
  src: url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap);
}

.Optima {
  font-family: Optima !important;
}

.Aileron {
  font-family: Aileron;
}

.cinzel {
  font-family: "Cinzel", serif;
  font-weight: 400!important;
}

.LibreBaskerville {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
}

.nav_bar {
  width: fit-content !important;
  gap: 50px;
  align-items: center;
}

.nav_min {
  margin-left: 210px;
}

.HelveticaLight {
  font-family: HelveticaLight;
}

.nav_bar .nav-link {
  font-family: HelveticaLight;
  color: black;
  font-weight: 400;
}

.haeder_logo {
  width: 220px;
}

.header_min {
  padding: 50px 0px;
}

.header_min .nav-item {
  align-items: center;
}

.header_min .nav-item a {
  color: black;
  text-decoration: none;
  font-family: HelveticaLight;
  font-weight: 500;
}

.header_min .nav-item .active {
  color: black;
  font-weight: 700;
  background-color: #efefef;
  padding: 5px 30px;
  border-radius: 100px;
}

.header_book a {
  color: #c6912d !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
}

.Helvetica {
  font-family: Helvetica;
}

.georgia {
  font-family: georgia;
}

.Laurentians_slider div:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.Laurentians_slider {
  width: 100%;
  overflow: hidden;
  margin-bottom: -5px;
  position: relative;
}

.Laurentians_slider .slick-initialized .slick-slide {
  position: relative;
}

.Laurentians_slider .slide .Montreal {
  transition: 3s;
  transform: scale(1);
}

.Laurentians_slider .slide:hover .Montreal {
  transition: 3s;
  transform: scale(1.5);
}


.Laurentians_slider .slide:hover .Helvetica {
  display: flex !important;
  transition: 2s;
  animation-name: text_top;
  animation-duration: 4s;
}

.Laurentians_slider .slide {
  overflow: hidden;
}

@keyframes Montreal {
  from {
    transform: scale(1.5);
  }

  to {
    transform: scale(1);
  }
}

@keyframes text_top {
  from {
    margin-top: -500px;
  }

  to {
    margin-top: 0px;
  }
}

.SushiBar {
  display: flex;
  grid-template-columns: repeat(3, 3fr);
  gap: 50px;
  margin-top: 40px;
}

.Tremblant-mt {
  margin-top: 30px;
}

.SushiBar p {
  margin-top: 10px;
}

.button {
  width: 100%;
}

.custom-btn {
  color: #fff;
  border-radius: 5px;
  padding: 10px 35px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  font-family: Helvetica;
  font-weight: 700;
  border-radius: 100px;
  overflow: hidden;
}

.btn-14 {
  background: #e7d5b1;
  border: none;
  z-index: 1;
  color: #000;
}

.btn-14:hover {
  color: white;
}

.btn-14:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

.btn-14:active {
  top: 2px;
}

.OurChalets_button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.publishing .slide {
  padding-right: 40px;
}

.publishing .Laurentians_slider::before {
  content: "";
  position: absolute;
  background-image: url(../src/Assets/Icons/Rectangle.png);
  background-position: left;
  height: 100%;
  width: 100px;
  right: -1px;
  z-index: 9;
}


.KozeChalets_Remove_Background .Laurentians_slider::before {
  content: "";
  position: absolute;
  background-image: none !important;
  background-position: left;
  height: 100%;
  width: 100px;
  right: -1px;
  z-index: 9;
}


.publishing .Laurentians_slider {
  overflow: inherit;
}

.publishing .slick-list {
  overflow: hidden;
}

.publishing .slick-next::before {
  background-color: transparent;
  color: transparent;
  overflow: hidden;
  border-radius: 100%;
  padding: 0;
  content: url(../src/Assets/Icons/left.png);
}

.publishing .slick-prev::before {
  content: url(../src/Assets/Icons/right.png);
}

.publishing .slick-prev {
  left: -50px !important;
  z-index: 999;
  margin-left: 15px !important;
  margin-right: 15px !important;

}

.publishing .slick-next {
  right: -50px !important;
  margin-left: 5px !important;
  margin-right: 15px !important;
}

.publishing .slick-prev,
.slick-next {
  top: 40% !important;
  margin-top: 0px !important;
}

.publishing .slick-prev,
.slick-next {
  opacity: 0.5;
}

.publishing .slick-prev:hover,
.slick-next:hover {
  opacity: 1;
}

.publishing .Laurentians_slider {
  margin-top: 50px;
  margin-bottom: -10px;
}

.publishing .Laurentians_slider .slide:hover .Montreal {
  transform: scale(1);
}

.publishing .Laurentians_slider .slide:hover .Helvetica {
  display: flex !important;
  transition: 2s;
  animation-name: none;
  animation-duration: 4s;
}

.Housekeeping {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 40px;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 2fr);
}

.Housekeeping .animated {
  width: 40% !important;
}

.Housekeeping .Housekeeping_td {
  display: flex;
  gap: 20px;

}

.Testimonial {
  border: 1px solid black;
  border-width: 1px 0px 1px 0px;
  width: 120px;
  text-align: center;
  margin: auto;
  text-transform: uppercase;
  font-family: Helvetica;
  font-size: 17px;
}

.Testimonial_min .slide {
  padding-right: 20px;
}

.Testimonial_min .readable {
  padding: 40px;
}

.sequence {
  border-radius: 0px 50px 0px 0px;
}

.sequence1 {
  border-radius: 50px 0px 0px 0px;
}

.sequence2 {
  border-radius: 0px 0px 0px 50px;
}

.Clients {
  width: 1500px;
}

.Testimonial_min .slick-track {
  display: flex;
}

.Testimonial_min .slick-list {
  display: flex;
}

.Testimonial_min .slick-slide div:nth-child(1) {
  height: 100%;
}

.signup .btn-14 {
  background-color: #e7d5b1;
  color: black;
  font-weight: 100;
  border-radius: 5px;
}


.Newsletter {
  display: flex;
  gap: 20px;
}

.Newsletter input {
  background-color: transparent;
  color: white;
  border: none;
  width: 100%;
  height: 100%;
}

.Newsletter input::placeholder {
  color: white;
  font-size: 18px;
}

.Newsletter input:focus {
  outline: none;
  border: none;
}

.Newsletter input:hover {
  outline: none;
  border: none;
}

.KozeEmail {
  border: 1px solid white;
  border-width: 0px 0px 1px 0px;
  width: 90%;
}

.Reserved_flex {
  display: flex;
  justify-content: space-between;
}

.signup {
  width: 180px;
}

.Reserved_flex ul {
  display: grid;
  gap: 19px;
}

.instagramwhite {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Reserved_flex ul li a {
  color: white;
  text-decoration: none;
}

.instagramwhite img {
  cursor: pointer;
}

.Testimonial_min .Clients .slick-initialized::before {
  content: "";
  position: absolute;
  left: 0px;
  background-image: url(../src/Assets/Icons/Clientsshodow.png);
  background-size: 100% 100%;
  height: 100%;
  width: 120px;
  top: 0px;
  background-position: top;
  z-index: 9;
  left: -40px;
}

.Testimonial_min .Clients .slick-initialized::after {
  content: "";
  position: absolute;
  left: 0px;
  background-image: url(../src/Assets/Icons/Clientsshodow.png);
  background-size: 100% 100%;
  height: 100%;
  width: 120px;
  top: 0px;
  background-position: top;
  z-index: 9;
  left: 100%;
  margin-left: -70px;
  rotate: 180deg;
}

.Testimonial_min .Clients {
  overflow: hidden;
  position: relative;
}

.Testimonial_min .slick-next::before {
  background-color: transparent;
  color: transparent;
  overflow: hidden;
  border-radius: 100%;
  padding: 0;
  content: url(../src/Assets/Icons/left.png);
}

.Testimonial_min .slick-prev::before {
  content: url(../src/Assets/Icons/left.png);
}

.Testimonial_min .slick-prev {
  rotate: 180deg;
  left: -50px;
}

.Testimonial_min .slick-next {
  right: -25px;
}

.Testimonial_min .slick-prev,
.slick-next {
  top: 40%;
  z-index: 999;
}

.Testimonial_min .next-button {
  position: absolute;
  top: 50%;
  rotate: 180deg;
  z-index: 999;
  margin-top: -10px;
  cursor: pointer;
  left: 0px;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.Testimonial_min .prev-button {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -10px;
  cursor: pointer;
  margin-left: 10px !important;
  margin-right: 5x !important;
}

.new_navbar {
  gap: 20px;
}

.header_p {
  position: fixed !important;
  top: 0px;
  background-color: black;
  z-index: 99;
  width: 100%;
  transition: 1s;
}

.header_p .nav-item a {
  color: white;
}

.header_p .custom-dropdown2 div {
  color: white !important;
}

.header_p img {
  filter: brightness(0) invert(1);
}

.zoon_uot {
  transform: scale(1);
  overflow: hidden;
  transition: 2s;
}

.zoon_uot:hover .zoom_in {
  transform: scale(1.5) !important;
  transition: 1s;
}

.zoom_in {
  transition: 1s;
}

.animated {
  opacity: 1 !important;
}

@media (max-width: 1399px) {
  .nav_min {
    margin-left: 100px;
  }
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.header_min {
  z-index: 9999;
  position: relative;
}

.dubai_slider .slick-track {
  display: flex;
}

.dubai_slider .slick-initialized .slick-slide {
  width: 25% !important;
  height: 100vh !important;
}

.dubai_slider .slick-initialized .slick-slide:hover {
  width: 50% !important;
  transition: 2s;
}

.dubai_slider .slide:hover .Montreal {
  transform: scale(1) !important;
}

.Montreal {
  height: 100%;
  object-fit: cover;
}

.dubai_slider .slick-slide>div {
  height: 100%;
}

.dubai_slider .slide {
  height: 100%;
}

.dubai_slider {
  display: flex;
  width: 100%;
}

.dubai_slider .slide {
  width: 25% !important;
  height: 100vh;
}

.dubai_slider .slide:hover {
  width: 100% !important;
  transition: 2s;
}

.dubai_slider .active-slide {
  width: 100% !important;
  transition: 2s;
}

.Testimonial {
  border-width: 1px;
  position: relative;
}

.Testimonial::after {
  content: "";
  background-color: white;
  height: 25px;
  width: 10px;
  position: absolute;
  right: -5px;
  transition: 1s;
}

.Testimonial::before {
  content: "";
  background-color: white;
  height: 25px;
  width: 10px;
  position: absolute;
  left: -5px;
  transition: 1s;
}

.bounceInRight:hover .Testimonial::after,
.bounceInRight:hover .Testimonial::before {
  height: 0px;
}

.Contact_Information {
  display: flex;
  gap: 50px;
}

.Information_w {
  width: 40%;
}

.Information_t {
  width: 60%;
}

.conatac_data {
  padding: 80px 0px !important;
}

.Question_grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 30px;
  margin-top: 30px;
}

.Information_t input {
  width: 100%;
  background-color: transparent;
  border: none;
  border: 0.1px solid rgba(255, 255, 255, 0.575);
  border-radius: 100px;
  color: black;
  padding: 15px 10px;
  padding-left: 25px;
}

.Information_t input::placeholder {
  color: black;
}

.Information_t textarea::placeholder {
  color: black;
}

.Information_t textarea {
  width: 100%;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.575);
  border-radius: 100px;
  color: black;
  padding: 15px 10px;
  padding-left: 25px;
  margin-top: 30px;
  height: 100px;
}

.Information_t input:focus {
  outline: none;
}

.Information_t textarea:focus {
  outline: none;
}

.Information_t span {
  color: red;
}

.Question_grid button {
  background-color: black;
  border-radius: 100px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.rouned {
  background-color: #4a4a4a;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_Icons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.Josefin {
  font-family: "Josefin Sans", sans-serif;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

input {
  -webkit-transition: all 340282346638528860000000000000000000000s ease 340282346638528860000000000000000000000s;
  -moz-transition: all 340282346638528860000000000000000000000s ease 340282346638528860000000000000000000000s;
  -ms-transition: all 340282346638528860000000000000000000000s ease 340282346638528860000000000000000000000s;
  -o-transition: all 340282346638528860000000000000000000000s ease 340282346638528860000000000000000000000s;
  transition: all 340282346638528860000000000000000000000s ease 340282346638528860000000000000000000000s;
}

.Success {
  position: fixed;
  top: 0px;
  height: 100%;
  background-color: #00000069;
  width: 100%;
  left: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Success_center {
  background-color: #c6912d;
  width: 450px;
  border-radius: 10px;
  padding: 20px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cursor {
  cursor: pointer;
}

.Success_center .custom-btn {
  padding: 5px 32px;
  margin: auto;
  margin-top: 15px;
  width: 88px;
}

.Question_grid button {
  padding: 15px 35px;
}

.Loading {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 100%;
  left: 0px;
  background-color: #00000067;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.koze_home_slider .slick-dots {
  bottom: 90px;
}

.koze_home_slider .slick-dots li {
  background-color: transparent;
  width: fit-content;
  padding: 0px;
  margin: 0px;
  cursor: none;
}

.koze_home_slider .slick-dots li button {
  background-color: #e0c9aa;
  margin-left: 5px;
  margin-right: 5px;
  width: 20px;
  height: 10px;
  pointer-events: none;
  
  border-radius: 100px;
}

.koze_home_slider .slick-dots li button::after,
.koze_home_slider .slick-dots li button::before {
  display: none;
}

.koze_home_slider .slick-dots .slick-active button {
  background-color: white;
  width: 38px;
}

.koze_home_slider .slick-initialized .slick-slide {
  position: relative;
}
.question-container {
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #000;
  color: white;
}

.question-slide {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: transform 0.5s ease-in-out;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.progress-container {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: absolute;
  z-index: 999999;
}

.progress-bar {
  height: 5px;
  background-color: white;
  transition: width 0.3s ease;
}

.Your_answer input:focus {
  border-bottom: 1px solid #e0c9aa;
  transition: none !important;
  outline: none;
}

.Your_answer input {
  border-bottom: 1px solid #e0c9aa88;
  width: 100%;
  padding: 10px;
  color: #e0c9aa;
}

.Your_answer input::placeholder {
  color: #e0c9aa88;
}

.checkbox-container input[type="radio"] {
  display: none;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container {
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  gap: 20px;
}

.checkbox-container .checkmark {
  width: 30px;
  height: 30px;
  border: 2px solid rgba(255, 255, 255, 0);
  outline: 1px solid #e0c9aa88;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transition: background-color 0.2s;
  padding: 2px;
  border-radius: 100px;
}

.GuestFavourite .checkmark {
  border-radius: 0px;
}

.checkbox-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input[type="radio"]:checked+.checkmark::after {
  display: block;
}

.GuestFavourite .checkbox-container input[type="checkbox"]:checked+.checkmark::after {
  display: block;
}

.approve_a {
  color: white !important;
}

.checkbox-container .checkmark::after {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid #e0c9aa;
  background-color: #e0c9aa;
  border-radius: 100px;
}

.GuestFavourite .checkbox-container .checkmark::after {
  border-radius: 0px;
}

.GuestFavourite input[type="text"]:focus {
  outline: none !important;
}

.GuestFavourite input[type="text"] {
  transition: none;
}

.slider-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  flex: 1;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.scroll-item {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding: 20px 0px;
  flex-shrink: 0;
}

.scroll-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.dot-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-100%);
  display: flex;
}

.dot {
  background-color: #ddd;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #007bff;
}

.custom-footer .custom-dropdown2 {
  color: black !important;
}

.custom-footer .dropdown-menu li {
  color: white;
}

.custom-dropdown {
  width: 100%;
  padding: 0px;
}

.interiors {
  font-weight: 700;
  cursor: pointer;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  scrollbar-width: none;
}

.scroll-container {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.Testimonial_min .active-slide .readable {
  background-color: black !important;
  color: white !important;
  transition: 1s;
}

.Testimonial_min .prev-button {
  z-index: 999;
}

.white_class:hover {
  background-color: #d18e06 !important;
  color: white !important;
  border: none;
  outline: none;
}

.orange_class:hover {
  color: white !important;
  background-color: #d18e06 !important;
  border: none;
  outline: none;
}

.home_away .slick-dots {
  justify-content: left !important;
  color: #000 !important;
  top: 10px;
  border-bottom: 1px solid #4a4a4a;
  margin-bottom: -27px;
  margin-top: 30px;
}

.home_away .slick-dots li {
  width: fit-content !important;
  height: fit-content !important;
}

.home_away .slick-dots .slick-active {
  color: #000 !important;
  font-weight: 700;
  border-bottom: 1px solid black;
}

.home_away .slick-slider {
  display: flex !important;
  flex-direction: column-reverse !important;

}

.home_away .slick-prev,
.home_away .slick-next {
  display: none !important;
}

.contect_from span {
  margin-left: 30px;
}

.custom-dropdown2 {
  font-family: Optima !important;
}

.KozeEmail input::placeholder,
.KozeEmail input::placeholder {
  font-family: Optima !important;
}

@media (max-width: 1279px) {
  .container {
    max-width: 1279px;
    padding: 0px 20px;
  }
}

.dot_jh::after {
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  content: "";
  right: -6px;
  top: 21px;
}

.dot_jh {
  position: relative;
}

.Ask_Away input,
.Ask_Away textarea {
  border: 1px solid white !important;
}

.Ask_Away input::placeholder,
.Ask_Away textarea::placeholder {
  color: white;
}

.Ask_Away button {
  background-color: white;
  color: black;
}

.custom-ropy .dropdown-menu {
  height: 100px;
  overflow: hidden;
  overflow-y: scroll;
}

.header_currency .downArrowIcon {
  display: none;
}

.header_currency .custom-dropdown {
  margin: 0px;
  color: white;
}

.header_currency .custom-dropdown li {
  color: white;
}

.header_currency .dropdown-menu {
  width: 150px;
}

.custom-ropy .dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-ropy .dropdown-menu::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.custom-ropy .dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.Jeffery_Khoury:hover img {
  filter: brightness(0.5);
  transition: 1s;
}

.Jeffery_Khoury {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.Jeffery_Khoury .hidden {
  margin-left: -100px;
  transition: 3s;
}

.Jeffery_Khoury:hover .hidden {
  display: block;
  transition: 3s;
  margin-left: 0px;
}

.Here_center {
  align-items: center;
  gap: 60px;
}

.Here_center .font-48 {
  margin-bottom: 20px;
}

.marker_slider .publishing .Laurentians_slider::after {
  content: "";
  position: absolute;
  background-image: url(../src/Assets/Icons/Rectangle.png);
  background-position: left;
  height: 100%;
  width: 100px;
  left: 0px;
  z-index: 9;
  top: 0px;
  rotate: 180deg;
}

.white_class:hover .custom-dropdown2 {
  color: white !important;
}

.white_class:hover .downArrowIcon {
  filter: contrast(0.5);
}

@media (max-width: 699px) {

  .publishing .slick-prev,
  .slick-next {
    top: 40% !important;
    opacity: 0.5;
    z-index: 9999;
  }
}

.active_faq .plash {
  display: none;
}

.plash {
  color: #c6912d;
}

.maines {
  display: none;
  color: #c6912d;
}

.active_faq .maines {
  display: inline-block;
  color: #c6912d;
}

.input-error {
  border-bottom: 1px solid;
  border-color: red !important;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-weight: 600;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-weight: 600;
}

.newsletter_main_container {
  width: 100%;
}

.newsletter_main_container input {
  width: 100%;
  background-color: transparent;
  border: none;
  border: 0.1px solid rgba(255, 255, 255, 0.575);
  border-radius: 10px;
  color: black;
  padding: 8px 8px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 18px;
  text-align: left;
}

.newsletter_main_container input::placeholder {
  color: #7e7e7e;
}

.newsletter_main_container input:focus {
  outline: none;
}

.newsletter_main_container textarea:focus {
  outline: none;
}

.newsletter_main_container span {
  color: red;
}

.success-popup-content {
  animation-name: example;
  animation-duration: 2s;
}

@keyframes example {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.Currency_count {
  font-size: 134px;
  -webkit-text-stroke: 0.9px white;
  -webkit-text-fill-color: transparent;
  line-height: 102px;
}

.animtion_left {
  animation-name: example1;
  animation-duration: 1s;
}

.animtion_right .animtion_left {
  animation-name: exam !important;
  animation-duration: 1s;
  position: relative;
}

@keyframes example1 {
  0% {
    opacity: 0;
    margin-left: -100%;
  }

  100% {
    opacity: 1;
  }
}

@keyframes exam {
  0% {
    opacity: 0;
    right: -150%;
  }

  100% {
    opacity: 1;
    right: 0%;
  }
}

@media (max-width: 1050px) {
  .search_howernew:hover {
    background-color: #d4b285;
  }
}

@keyframes koze {
  0% {
    opacity: 1;
  }

  50% {
 
    opacity: 0.5;
  }

  100% {

    opacity: 1;
  }
}
.koze_logo_animation {
  animation-name: koze;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
  border-radius: 100%;
}

.page {
  display: grid;
  position: relative;
  width: 150px;
}

.page__txt {
  margin: auto;
}

.txt {
  color: transparent;
  display: flex;
  font-size: 40px;
  text-transform: uppercase;
}

.txt__character {
  animation: fall 2s linear infinite;
  animation-delay: calc(var(--i) * 200ms);
  color: rgb(224, 201, 170) !important;
  margin-left: 3px;
  margin-right: 3px;
  letter-spacing: 90px;
}

@keyframes fall {
  0% {
    opacity: 0;
  }

  50%,
  80% {
    opacity: 1;
    
  }

  95%,
  100% {
    opacity: 0;
  }
}

.city_item_hover {
  color: #000;
  background-color: transparent;
}

.city_item_hover:hover {
  color: #fff;
  background-color: #000;

}

@media (max-width:991px) {
  .Housekeeping .animated {
    width: 100% !important;
  }
}


.Landing_slider {
  width: 100%;
}

.Landing_slider .txt {

  font-size: 200px;
}

.Landing_slider .page {
  width: 840px;
}

.Commitment {
  border: 1px solid black;
  height: 100px;
  width: 100px;
}

.Commitment::before {
  background-color: #000;
  height: 85px;
  width: 85px;
}

.Commitment img {
  width: 50px;
  height: 50px;
}

.custom-placeholder::placeholder {
  color: gray!important; 
}

html {
  scrollbar-width: thin;        
  scrollbar-color: #d4b285 black; 
}

::-webkit-scrollbar-thumb {
  background-color: #d4b285; 
}

::-webkit-scrollbar {
  width: 8px; 
}




.rpv-core__inner-page {
  background-color: #000 !important;
}
